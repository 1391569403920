import React, { useContext } from "react";
import { MenuContext } from "../MenuManager";
import './style.scss'
import cn from "classnames";

export default function MenuButton() {
  const { setOpen, open } = useContext(MenuContext);
  return (
    <div className={cn("menu-button-wrap", { open })}>
      <button className="menu-button" onClick={() => setOpen(!open)}>
        <span />
      </button>
    </div>
  )
}
