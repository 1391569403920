import React from "react";
import "./style.scss";

export default function Featured() {
  return (
    <section className="featured-section" data-scroll-section>
      <div className="featured-row-layout">
        <h6>Customer</h6>
        <img
          src="https://images.squarespace-cdn.com/content/v1/6220afe8a86fbb34c92df1c2/ae7f065e-7a96-4983-9dba-f300114e4b4f/Spherio+Group+Logo.png?format=1500w"
          alt="Spherio Group logo"
          data-scroll
        />
      </div>

      <div className="featured-column-layout">
        <h6></h6>
        <img className="yay-img"
        src="https://res.cloudinary.com/hxkazwndf/image/upload/f_auto,c_limit,w_1980,q_auto/v1613321296/static/story/our-story-4.jpg" 
        alt="Yaytrade app prototype" data-scroll />
      </div>
    </section>
  );
}
