import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader
				title='My Role: Fullstack developer'
				subtitle='Length: 3 months'
			/>
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>The Company</h2>
				Sandvik Coromant is at the forefront of manufacturing tools, machining
				solutions and knowledge that drive industry standards and innovations
				demanded by the metalworking industry.
				<br />
				<br />
				<h2>My Role</h2>
				Fullstack developer Consultant creating a{' '}
				<abbr title='Proof of Concept'>POC</abbr> chatbot with the purpose of
				facilitate customer service at Sandvik Coromant.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Scrum as method.
				Chatbot was created and tested in different environments.
				<br />
				The project involved building flow charts, implementing dialogues and
				training the AI model. Chatbot was built in DialogFlow and Microsoft Bot
				Framework composer.
				<br />
				<br />
				We worked with programs such as Google Cloud platform, Luis, Microsoft
				Azure, NLP, Notion, Slack, Teams, Visual Studio Code, Discord, Miro,
				Figma, VS Code, Trello, Teams, Discord, Github
			</div>

			<div className='mobile'>
				<h2>The Company</h2>
				Sandvik Coromant is at the forefront of manufacturing tools, machining
				solutions and knowledge that drive industry standards and innovations
				demanded by the metalworking industry.
				<br />
				<br />
				<h2>My Role</h2>
				Fullstack developer Consultant creating a{' '}
				<abbr title='Proof of Concept'>POC</abbr> chatbot with the purpose of
				facilitate customer service at Sandvik Coromant.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Scrum as method.
				Chatbot was created and tested in different environments, The project
				involved building flow charts, implementing dialogues and training the
				AI model. Chatbot was built in DialogFlow and Microsoft Bot Framework
				composer.
				<br />
				<br />
				We worked with programs such as Google Cloud platform, Luis, Microsoft
				Azure, NLP, Notion, Slack, Teams, Visual Studio Code, Discord, Miro,
				Figma, VS Code, Trello, Teams, Discord, Github
			</div>
			<section className='image-container'>
				<img
					className='img-sv'
					src='https://miro.medium.com/max/744/0*Hhue8P95QnkC2gvi'
					alt='robot with text Chatbot'
				/>
			</section>
		</section>
	);
}
