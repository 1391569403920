import React, { useRef, useReducer, useContext } from "react";
import Title from './Title';
import Image from './Image';
import animate from './animate';
import { Hash } from 'react-feather';
import { CursorContext } from '../CustomCursor/CursorManager'
import cn from 'classnames'
import { Link } from "react-router-dom";

import './style.scss'

const initialState = {
	opacity: 0,
	parallaxPos: { x: 0, y: -20 },
	scale: 0.8,
	totationPosition: 0,
	active: false, 
}

function reducer(state, action) {
  switch (action.type) {
    case "MOUSE/ENTER":
      return { ...state, active: true };
    case "MOUSE/LEAVE":
      return { ...state, active: false };

    case "MOUSE/COORDINATES": {
      return {
        ...state,
        parallaxPos: action.payload,
      };
    }
    case "CHANGE/ROTATION": {
      return {
        ...state,
        rotationPosition: action.payload,
      };
    }

    case "CHANGE/SCALE": {
      return {
        ...state,
        scale: action.payload,
      };
    }

    case "CHANGE/OPACITY": {
      return {
        ...state,
        opacity: action.payload,
      };
    }
    default:
      throw new Error();
  }
}

const ProjectItem = ({ project, itemIndex }) => {
	const listItem = useRef(null);
	const { setSize } = useContext(CursorContext);
	const [state, dispatch] = useReducer(reducer, initialState)
	const easeMethod = 'easeInOutCubic'

	const parallax = (event, action) => {
		const speed = -5
		const x = (window.innerWidth - event.pageX  * speed) / 100;
		const y = (window.innerHeight - event.pageY * speed) / 100;

		dispatch({type: 'MOUSE/COORDINATES', payload: {x, y}})
	}

	const handleSetRotation = () => {
    // Random between -15 and 15
    const newRotation =
      Math.random() * 15 * (Math.round(Math.random()) ? 1 : -1);

    animate({
      fromValue: state.rotationPosition,
      toValue: newRotation,
      onUpdate: (value, callback) => {
        dispatch({ type: "CHANGE/ROTATION", payload: value });
        callback();
      },
      onComplete: () => {},
      duration: 500,
      easeMethod: easeMethod,
    });
  };

	const handleSetScale = (initialScale, newScale, duration) => {
    animate({
      fromValue: initialScale,
      toValue: newScale,
      onUpdate: (value, callback) => {
        dispatch({ type: "CHANGE/SCALE", payload: value });
        callback();
      },
      onComplete: () => {},
      duration: duration,
      easeMethod: easeMethod,
    });
  };

		const handleOpacity = (initalOpacity, newOpacity, duration) => {
		animate({
			fromValue: initalOpacity,
			toValue:  newOpacity,
			onUpdate: (newOpacity, callback) => {
				dispatch({type: 'CHANGE/OPACITY', payload: newOpacity})
				callback()
			},
		onComplete: () => {},
      duration: 500,
      easeMethod: easeMethod,
    });
  };
	const handleMouseEnter  = () => {
		handleSetScale(0.8, 1, 500)
		handleOpacity(0, 1, 500)
		handleSetRotation(state.rotationPosition, 500);
		listItem.current.addEventListener('mousemove', parallax)
		dispatch({type: 'MOUSE/ENTER'})
		setSize("regular");
	}

	const handleMouseLeave = () => {
		listItem.current.removeEventListener('mousemove', parallax)
		handleOpacity(1, 0, 800)
		handleSetScale(1, initialState.scale, 500)
		handleSetRotation(state.rotationPosition, 500);
		dispatch({type: 'MOUSE/COORDINATES', payload: initialState.parallaxPos})
		dispatch({type: 'MOUSE/LEAVE'})
		setSize("small");
	}

  return (
		<>
			<li  ref={listItem} className="project-item-container">
      <Link to={project.link}>
				<Title
        title={project.title}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}

				/></Link>
				<Image url={project.url}  
				opacity={state.opacity} 
				parallaxPos={state.parallaxPos}
				scale={state.scale}
				rotationPosition={state.rotationPosition}
				/>

				<div className={cn("info-block", {'as-active': state.active})}>
				<p className="info-block-header">
						<span>
							<Hash />0
							{itemIndex}
						</span>
					</p>
					{project.info?.map((element) => (
						<p key={element}>
							<span>{element}</span>
						</p>
					))}
				</div>
			</li>
		</>
  )
}

export default ProjectItem
