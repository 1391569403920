import React, { useState, useRef, useEffect } from "react";
import ProjectItem from "../components/ProjectItem";
// import ProjectHeader from "../components/ProjectHeader";
import CursorManager from "../components/CustomCursor/CursorManager";
import CustomCursor from "../components/CustomCursor/index";
import MenuManager from "../components/Menu/MenuManager";
import Menu from "../components/Menu";
import { pageData } from "../data";
import "../style.scss";

export default function ProjectPage() {
  const menuItems = useRef(null);
  const [renderItems, setRenderItems] = useState(pageData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cloneItems = () => {
    // Get the height of one menu item
    const itemHeight = menuItems.current.childNodes[0].offsetHeight;
    // How many items fit in the window?
    const fitMax = Math.ceil(window.innerHeight / itemHeight);

    // Create [fitIn] clones from the beginning of the list

    // Add clones
    const clonedItems = [...renderItems]
      .filter((_, index) => index < fitMax)
      .map((target) => {
        return target;
      });

    // All clones height

    setRenderItems([...renderItems, ...clonedItems]);
    return clonedItems.length * itemHeight;
  };

  const getScrollPos = () => {
    return (
      (menuItems.current.pageYOffset || menuItems.current.scrollTop) -
      (menuItems.current.clientTop || 0)
    );
  };
  const setScrollPos = (pos) => {
    menuItems.current.scrollTop = pos;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initScroll = () => {
    // Scroll 1 pixel to allow upwards scrolling
    const scrollPos = getScrollPos();
    if (scrollPos <= 0) {
      setScrollPos(1);
    }
  };

  useEffect(() => {
    const clonesHeight = cloneItems();
    initScroll();
    menuItems.current.style.scrollBehavior = "unset";

    const scrollUpdate = () => {
      const scrollPos = getScrollPos();

      if (clonesHeight + scrollPos >= menuItems.current.scrollHeight) {
        // menuItems.current.style.scrollBehavior = "unset";
        // Scroll to the top when you’ve reached the bottom
        setScrollPos(1); // Scroll down 1 pixel to allow upwards scrolling
        // menuItems.current.style.scrollBehavior = "smooth";
      } else if (scrollPos <= 0) {
        // Scroll to the bottom when you reach the top
        setScrollPos(menuItems.current.scrollHeight - clonesHeight);
        // menuItems.current.style.scrollBehavior = "smooth";
      }
    };

    menuItems.current.addEventListener("scroll", scrollUpdate);

    //infinity scroll - does not work on mobile - creates infinity loop
    //   return () => {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     //menuItems.current.removeEventListener("scroll", scrollUpdate);
    //   };
    // }, [cloneItems, initScroll]);

    // No infinity scroll but works on phone
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      //menuItems.current.removeEventListener("scroll", scrollUpdate);
    };
  }, []);

  return (
    <>
      <CursorManager>
        <CustomCursor />
        <MenuManager>
          <Menu />
          {/* <ProjectHeader /> // UX & UI Payground */}
          <div id="projectPage-list" className="projectPage-list">
            <ul ref={menuItems}>
              {renderItems.map((project, index) => (
                <ProjectItem key={index} project={project} itemIndex={index} />
              ))}
            </ul>
          </div>
        </MenuManager>
      </CursorManager>
    </>
  );
}

/*

export default function ProjectPage() {
  const menuItems = useRef(null);
  const [renderItems, setRenderItems] = useState(pageData);

  const cloneItems = () => {
    // Get the height of one menu item
    const itemHeight = menuItems.current.childNodes[0].offsetHeight;
    // How many items fit in the window?
    const fitMax = Math.ceil(window.height / itemHeight);
    // Create [fitIn] clones from the beginning of the list

    // Add clones
    const clonedItems = [...renderItems]
      .filter((_, index) => index < fitMax)
      .map((target, index) => {
        return target;
      });

    setRenderItems([...renderItems, ...clonedItems]);
    return clonedItems.length * itemHeight;
  };

  const getScrollPos = () => {
    return (
      (menuItems.current.pageYOffset || menuItems.current.scrollTop) -
      (menuItems.current.clientTop || 0)
    );
  };

  const setScrollPos = (pos) => {
    menuItems.current.scrollTop = pos;
  };

  const initScroll = () => {
    // Scroll 1 pixel to allow upwards scrolling
    const scrollPos = getScrollPos();
    if (scrollPos <= 0) {
      setScrollPos(1);
    }
  };

  useEffect(() => {
    const clonesHeight = cloneItems();
    initScroll();
    menuItems.current.style.scrollBehavior = "unset";

    const scrollUpdate = () => {
      const scrollPos = getScrollPos();

      if (clonesHeight + scrollPos >= menuItems.current.scrollHeight) {
        // menuItems.current.style.scrollBehavior = "unset";
        // Scroll to the top when you’ve reached the bottom
        setScrollPos(1); // Scroll down 1 pixel to allow upwards scrolling
        // menuItems.current.style.scrollBehavior = "smooth";
      } else if (scrollPos <= 0) {
        // Scroll to the bottom when you reach the top
        setScrollPos(menuItems.current.scrollHeight - clonesHeight);
        // menuItems.current.style.scrollBehavior = "smooth";
      }
    };

    menuItems.current.addEventListener("scroll", scrollUpdate);

    // return () => {
    //   menuItems.current.removeEventListener("scroll", scrollUpdate);
    // };
  }, []);

  return (
    <>
      <CursorManager>
        <CustomCursor />
        <MenuManager>
          <Menu />
          <ProjectHeader />
          <div id="projectPage-list" className="projectPage-list">
            <ul ref={menuItems} >
              {renderItems.map((project, index) => (
                <ProjectItem key={index} project={project} itemIndex={index} />
              ))}
            </ul>
          </div>
        </MenuManager>
      </CursorManager>
    </>
  );
}

*/

/**
 

export default function ProjectPage() {
const menuItems = useRef(null);
  const [renderItems, setRenderItems] = useState(pageData);

  const cloneItems = () => {
    // Get the height of one menu item
    const itemHeight = menuItems.current.childNodes[0].offsetHeight;
    // How many items fit in the window?
    const fitMax = Math.ceil(window.innerHeight / itemHeight);
 
    // Create [fitIn] clones from the beginning of the list

    // Add clones
    const clonedItems = [...renderItems]
      .filter((_, index) => index < fitMax)
      .map((target) => {
        return target;
      });

    // All clones height

    setRenderItems([...renderItems, ...clonedItems]);
    return clonedItems.length * itemHeight;
  };

  const getScrollPos = () => {
    return (
      (menuItems.current.pageYOffset || menuItems.current.scrollTop) -
      (menuItems.current.clientTop || 0)
    );
  };
  const setScrollPos = (pos) => {
    menuItems.current.scrollTop = pos;
  };

  const initScroll = () => {
    // Scroll 1 pixel to allow upwards scrolling
    const scrollPos = getScrollPos();
    if (scrollPos <= 0) {
      setScrollPos(1);
    }
  };

  useEffect(() => {
    const clonesHeight = cloneItems();
    initScroll();
    menuItems.current.style.scrollBehavior = "unset";

    const scrollUpdate = () => {
      const scrollPos = getScrollPos();

      if (clonesHeight + scrollPos >= menuItems.current.scrollHeight) {
        // menuItems.current.style.scrollBehavior = "unset";
        // Scroll to the top when you’ve reached the bottom
        setScrollPos(1); // Scroll down 1 pixel to allow upwards scrolling
        // menuItems.current.style.scrollBehavior = "smooth";
      } else if (scrollPos <= 0) {
        // Scroll to the bottom when you reach the top
        setScrollPos(menuItems.current.scrollHeight - clonesHeight);
        // menuItems.current.style.scrollBehavior = "smooth";
      }
    };

    menuItems.current.addEventListener("scroll", scrollUpdate);

    // return () => {
    //   menuItems.current.removeEventListener("scroll", scrollUpdate);
    // };
  }, []);

  return (
    <>
      <CursorManager>
        <CustomCursor />
        <MenuManager>
          <Menu />
          <ProjectHeader />
          <div id="projectPage-list" className="projectPage-list">
            <ul ref={menuItems} >
              {renderItems.map((project, index) => (
                <ProjectItem key={index} project={project} itemIndex={index} />
              ))}
            </ul>
          </div>
        </MenuManager>
      </CursorManager>
    </>
  );
}

 */
