import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';
import { GitHub, Layout } from 'react-feather';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader title='My Role: Student' subtitle='Length: 6 months' />
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>The Company</h2>
				About the school here
				<br />
				<br />
			</div>

			<div className='mobile'></div>
			<section>
				<div className='case-wrapper'>
					<h2>Cases</h2>
					<p classname='case'>
						Waves Chat App
						<a href='/'>
							<GitHub />
						</a>
						<a href='/'>
							<Layout />
						</a>
					</p>
				</div>
			</section>
			<section>
				<img
					src='https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
					alt='Workstation with several computers'
				/>
			</section>
		</section>
	);
}
