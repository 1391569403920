import React from 'react';
import './style.scss';

export default function Footer() {
	return (
		<section data-scroll-section data-scroll-position='bottom'>
			<div className='footer-container-sogeti' data-scroll>
				<a
					data-scroll
					href='https://www.sogeti.se/'
					target='_blank'
					rel='noopener noreferrer'>
					Visit Sogeti{' '}
				</a>
			</div>
		</section>
	);
}
