import { GitHub, Instagram, Linkedin, MessageCircle, Mail, HelpCircle } from "react-feather";

export const internalLinks = [
  {
    number: '#1',
    link: '/',
    page: <span>Home</span>,
    img: "https://images.unsplash.com/photo-1524168576969-c32baddd7006?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    number: '#2',
    link: '/about',
    page: <span>About</span>,
    img:
      "https://images.pexels.com/photos/2882509/pexels-photo-2882509.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    number: '#3',
    link: '/project',
    page: <span>Projects</span>,
    img:
      "https://images.unsplash.com/photo-1600132806608-231446b2e7af?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80",
  },
  {
    number: '#4',
    link: '/work',
    page: <span>Work</span>,
    img:
      "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80",
  },
  {
    number: '#5',
    link: '/skills',
    page: <span>Skills</span>,
    img:
      "https://images.unsplash.com/photo-1508317469940-e3de49ba902e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    number: '#6',
    link: '/contact',
    page: <span>Contact</span>,
    img:
      "https://images.pexels.com/photos/821754/pexels-photo-821754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

export const externalLinks = [
  {
    number: '#10',
    url: "https://github.com/Andrea-Osmar",
    component: <GitHub />,
  },
  {
    number: '#11',
    url: "https://www.instagram.com/twigs88/?hl=en",
    component: <Instagram />,
  },
  {
    number: '#12',
    url: "https://www.linkedin.com/in/andrea-osmar/",
    component: <Linkedin />,
  },
  {
    number: '#13',
    url: "https://www.messenger.com/t/andrea.osmar",
    component: <MessageCircle />,
  },
  {
    number: '#14',
    url: "mailto:andrea88@live.se",
    component: <Mail />,
  },
   {
    number: '#15',
    url: "https://iqwkvh7ldhv.typeform.com/to/ilmg5z29",
    component: <HelpCircle />,
  },
];