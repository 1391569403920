import React from "react";
import pdf from "../../../assets/Presentation.pdf";
import pdf1 from "../../../assets/Typography.pdf";
import "./style.scss";

export default function Footer() {
  return (
    <section data-scroll-section data-scroll-position="bottom">
      <div className="footer-container" data-scroll>
        <a
          data-scroll
          href="https://www.figma.com/proto/EgmuI0hMoCrIUjmwNwY0Jf/Enjoy-Your-Home?page-id=286%3A2958&node-id=292%3A3055&viewport=743%2C614%2C0.16&scaling=scale-down&starting-point-node-id=292%3A3055"
          target="_blank"
          rel="noopener noreferrer"
        >
          UI Design{" "}
        </a>
        <a data-scroll href={pdf} target="_blank" rel="noopener noreferrer">
          Presentation
        </a>
        <a data-scroll href={pdf1} target="_blank" rel="noopener noreferrer">
          Typography
        </a>
      </div>
    </section>
  );
}