import React, { useContext } from "react";
import { MenuContext } from "../MenuManager";
import { Link } from "react-router-dom";
import cn from "classnames";
import './style.scss'
import { externalLinks, internalLinks  } from "../../../links"

export default function MenuContent() {
  const { open } = useContext(MenuContext);
  return (
    <div className="menu-holder">
      <div className={cn("menu-inside", { open })}>
        <div className="menu-nav-container">
          <ul className="internal-nav-links">
            {internalLinks.map((link) => (
              <li key={link.number}>
              <Link to={link.link}>{link.page}</Link>
                <a href={link.url} target="_blank"
                rel="noopener noreferrer">{link.component}</a>
                <img src={link.img} alt='menu item'/>
              </li>
            ))}
          </ul>
          <ul className="external-nav-links">
            {externalLinks.map((link) => (
              <li key={link.url}>
                <a href={link.url}>{link.component}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
