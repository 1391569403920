import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader
				title='My Role: Flutter Developer'
				subtitle='Length: 3 months'
			/>
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>The Company</h2>
				IKEA is a home furnishings company founded in Sweden. IKEA company
				vision has been to create a better everyday life for the many people by
				offering a wide range of well-designed, functional home furnishings at
				prices so low that as many people as possible can afford them.
				<br />
				<br />
				<h2>My Role</h2>
				Frontend developer consultant creating a{' '}
				<abbr title='Proof of Concept'>POC</abbr> app.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Scrum as method.
				App was built in Dart & Flutter.
				<br />
				<br />
				We worked with programs such as Figma, Android Studio, VS Code, Trello,
				Teams, Discord, Slack, Github
			</div>

			<div className='mobile'>
				<h2>The Company</h2>
				IKEA is a home furnishings company founded in Sweden. IKEA company
				vision has been to create a better everyday life for the many people by
				offering a wide range of well-designed, functional home furnishings at
				prices so low that as many people as possible can afford them.
				<br />
				<br />
				<h2>My Role</h2>
				Frontend developer consultant creating a{' '}
				<abbr title='Proof of Concept'>POC</abbr> app.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Scrum as method.
				App was built in Dart & Flutter.
				<br />
				<br />
				We worked with programs such as Figma, Android Studio, VS Code, Trello,
				Teams, Discord, Slack, Github
			</div>
			<section>
				<div className='img-wrapper'>
					<img
						className='img-ikea'
						src='https://images.unsplash.com/photo-1614186794187-ed3b3fa62461?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
						alt='Ikea catalogue'
					/>
					<img
						className='img-ikea'
						src='https://images.unsplash.com/photo-1583847268964-b28dc8f51f92?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
						alt='Ikea furniture'
					/>
				</div>
			</section>
		</section>
	);
}
