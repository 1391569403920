import React from "react";
import "./style.scss";

export default function Footer() {
  return (
    <section data-scroll-section data-scroll-position="bottom">
      <div className="footer-container" data-scroll>
        {" "}
      </div>
    </section>
  );
}