import React from "react";
import Header from "../components/Header";
import ContactButton from "../components/ContactButton";
import MenuManager from "../components/Menu/MenuManager";
import "../style.scss";

function Home() {
  return (
    <>
      <MenuManager>
        <Header />
        <div className="main-container" id="main-container">
          <h1>
            Andrea <br />
            Osmar <br />
            ux / ui <br />
            {/* frontend */}
          </h1>
          <ContactButton />
        </div>
      </MenuManager>
    </>
  );
}

export default Home;
