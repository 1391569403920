import CPACCL from './assets/CPACC.L.png';
import CPACC from './assets/CPACC.png';
import AZL from './assets/AZ.L.png';
import AZ from './assets/AZ.png';
import EHL from './assets/EH.L.png';
import EH from './assets/EH.png';
import google from './assets/google.jpg';
import WUX from './assets/W.UX.png';
import FUX from './assets/F.UX.png';
import technigoL from './assets/technigo.L.png';
import technigo from './assets/technigo.png';

export const galleryData = [
	{
		id: 1,
		imgSrc: CPACCL,
		imgSrcL: CPACC,
	},
	{
		id: 2,
		imgSrc: AZL,
		imgSrcL: AZ,
	},
	{
		id: 3,
		imgSrc: EHL,
		imgSrcL: EH,
	},
	{
		id: 4,
		imgSrc: google,
		imgSrcL: WUX,
	},
	{
		id: 5,
		imgSrc: google,
		imgSrcL: FUX,
	},
	{
		id: 6,
		imgSrc: technigoL,
		imgSrcL: technigo,
	},
];
