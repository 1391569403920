import React from "react";
import "./style.scss";
import logo from "../../../assets/stlogo.png";


export default function Featured() {
  return (
    <section className="featured-section" data-scroll-section>
      <div className="featured-row-layout">
        <h6>Consultant Company</h6>
        <img  
        src="https://www.sogeti.se/globalassets/sweden/images/sogeti---our-spirit.jpg" 
        alt="Sogeti buzzwords" data-scroll />
      </div>

      <div className="featured-column-layout">
        <h6></h6>
        <img
        className="sogeti-img"
          src={logo}
          alt="Sogeti heart logo"
          data-scroll
        />
      </div>
    </section>
  );
}
