export const pageData = [
  {
    link: '/yay',
    url:
      "https://res.cloudinary.com/hxkazwndf/image/upload/f_auto,c_limit,w_1400,q_auto/v1613321296/static/story/our-story-4.jpg",
    title: "Yaytrade",
    info: ["Frontend", "UI Design", "E-commerce"],
  },
  {
    link: "/eyh",
    url:
      "https://www.yourtango.com/sites/default/files/styles/header_slider/public/image_blog/MarieKondoQuotes.jpg?itok=Ai1G2nAt",
    title: "Enjoy Your Home",
    info: ["UX Design", "UI Design", "Typography"],
  },
  {
    link: '/st',
    url:
      "https://www.sogeti.se/globalassets/sweden/images/355x215/11_saker_du_inte_visste_om_sogeti_355x215.jpg",
    title: "Sogeti",
    info: ["Consultant", "UX/UI Design", "Fullstack Developer"],
  },
  {
    link: '/dt',
    url:
      "https://blog-frontend.envato.com/cdn-cgi/image/width=1200,quality=85,format=auto/uploads/2021/03/codelikeagirl.jpg",
    title: "DataTjej",
    info: ["Event", "Conference", "Volunteer"],
  },
  {
    link: '/mt',
    url:
      "https://via.tt.se/data/images/00159/4f96b62b-a9bd-4b8b-a979-c21e379d2660.jpg",
    title: "Mälardalstrafik",
    info: ["Accessibility", "UI Design", "Travel ticket app"],
  },
  {
    link: '/sg',
    url:
      "https://images.unsplash.com/photo-1592503254549-d83d24a4dfab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80",
    title: "Saint-Gobain",
    info: ["Fullstack", "E-commerce", "POC"],
  },
  {
    link: '/ikea',
    url:
      "https://images.unsplash.com/photo-1629921976897-b4ce6795e560?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1420&q=80",
    title: "Ikea",
    info: ["Flutter App", "UI Design", "POC"],
  },
  {
    link: '/sv',
    url:
      "https://miro.medium.com/max/744/0*Hhue8P95QnkC2gvi",
    title: "Sandvik",
    info: ["Fullstack", "Chatbot", "POC"],
  },
  {
      link: '/tk',
    url:
      "https://images.unsplash.com/photo-1516321497487-e288fb19713f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    title: "Tjejer Kodar",
    info: ["Volunteer", "Code Coach", "Frontend"],
  },
  // {
  //   link: '/tg',
  //   url:
  //     "https://images.unsplash.com/photo-1564865878688-9a244444042a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  //   title: "Technigo",
  //   info: ["Frontend bootcamp", "UI Design", "Education"],
  // },
];