import React from 'react';
import './style.scss';

export default function Title({ title, handleMouseEnter, handleMouseLeave, link }) {
  return (
    <div className="title-item"
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
      <h2 className="menu-title">
        {title}
      </h2>
      <h2 className="menu-title clone">{title}</h2>
    </div>
  )
}




