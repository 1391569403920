import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';
import EYH from '../../../assets/EYH.JPG';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader title='Role: UX/UI Designer' subtitle='Length: 6 months' />
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>The Client</h2>
				Owner and founder of Enjoy Your Home, Vera Keohane, wanted a new look to
				her website. She was in need of digital decluttering! <br />
				Vera Keohane is Ireland’s first KonMari consultant and she is on a
				mission to help others transform their homes to clutter free and
				organized environments, paving the way to lives that Sparks Joy.
				<br />
				<br />
				<h2>The Project</h2>
				Throughout the project we followed the Double Diamond Method (British
				Design Council) and started with a thorough UX research. The project
				included stakeholder interviews, user interviews, competitor analysis,
				empathy & experience mapping, creating personas & storyboarding as well
				as a problem statement. <br />
				User flows, typography and Wireframes were created in Figma. The outcome
				was tested by several users before presented to the client.
				<h2>The Result</h2>
				Since Vera loved her fonts, colors and icons we decided to keep as much
				as possible to not change too much of her personality in the new design.
			</div>
			<div className='mobile'>
				<h2>The Client</h2>
				Owner and founder of Enjoy Your Home, Vera Keohane, wanted a new look to
				her website. She was in need of digital decluttering! <br />
				Vera Keohane is Ireland’s first KonMari consultant and she is on a
				mission to help others transform their homes to clutter free and
				organized environments, paving the way to lives that Sparks Joy.
				<br />
				<br />
				<h2>The Project</h2>
				Throughout the project we followed the Double Diamond Method (British
				Design Council) and started with a thorough UX research. The project
				included stakeholder interviews, user interviews, competitor analysis,
				empathy & experience mapping, creating personas & storyboarding as well
				as a problem statement. <br />
				User flows, typography and Wireframes were created in Figma. The outcome
				was tested by several users before presented to the client.
				<h2>The Result</h2>
				Since Vera loved her fonts, colors and icons we decided to keep as much
				as possible to not change too much of her personality in the new design.
			</div>
			<section className='image-container'>
				<img
					className='img-eyh'
					src={EYH}
					alt='Prototype of Enjoy your home website'
				/>
			</section>
		</section>
	);
}
