import React from "react";
import { Link } from "react-router-dom";
import MenuManager from "../components/Menu/MenuManager";
import Menu from "../components/Menu";

import "./error.scss";

export default function Error() {
  return (
    <MenuManager>
      <Menu />
      <section className="error-container">
        <div className="error-wrapper">
          <h1>Something went wrong...</h1>
          <Link to="/">
            <button className="btn">Back Home</button>
          </Link>
        </div>
      </section>
    </MenuManager>
  );
}
