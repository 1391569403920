import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from '../src/Pages/Home'
import About from '../src/Pages/About'
import ProjectPage from '../src/Pages/ProjectPage'
import Work from '../src/Pages/Work'
import Skills from '../src/Pages/Skills'
import Contact from '../src/Pages/Contact'
import EYH from './Pages/EYH/index'
import YAY from './Pages/YAY/index'
import DT from './Pages/DT/index'
import ST from './Pages/SOGETI/index'
import SG from './Pages/SG/index'
import MT from './Pages/MT/index'
import IK from './Pages/IKEA/index'
import SV from './Pages/SV/index'
import TK from './Pages/TK/index'
import TG from './Pages/TG/index'

import Error from './Pages/Error'
import "./style.scss";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/work" element={<Work />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/eyh" element={<EYH />} />
        <Route path="/yay" element={<YAY />} />
        <Route path="/dt" element={<DT />} />
        <Route path="/st" element={<ST />} />
        <Route path="/sg" element={<SG />} />
        <Route path="/mt" element={<MT />} />
        <Route path="/ikea" element={<IK />} />
        <Route path="/sv" element={<SV />} />
        <Route path="/tk" element={<TK />} />
        <Route path="/tg" element={<TG />} />

        <Route path="/404" element={<Error />} />
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;
