import React from "react";
import "./style.scss";

export default function Featured() {
  return (
    <section className="featured-section" data-scroll-section>
      <div className="featured-row-layout">
        <h6>Company</h6>
        <img
          src="https://coursereport-s3-production.global.ssl.fastly.net/uploads/school/logo/511/original/technigo-logo.png"
          alt="Technigo logo"
          data-scroll
        />
      </div>

      <div className="featured-column-layout">
        <h6>The School</h6>
        <img src="https://images.unsplash.com/photo-1634836023845-eddbfe9937da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" 
        alt="computer and screen in dark room" data-scroll />
      </div>
    </section>
  );
}
