import React, { useEffect } from 'react';
import './about.scss';
import locomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss';
import profile from '../assets/profile.JPG';
import profile1 from '../assets/profile1.JPG';
import CV from '../assets/CV.pdf';
import MenuManager from '../components/Menu/MenuManager';
import Menu from '../components/Menu';

export default function About() {
	const scrollRef = React.createRef();
	useEffect(() => {
		const scroll = new locomotiveScroll({
			el: scrollRef.current,
			smooth: true,
			getDirection: true,
			getSpeed: true,
			smartphone: {
				smooth: false,
			},
			tablet: {
				smooth: true,
			},
		});
	});

	return (
		<>
			<MenuManager>
				<Menu />
				<div
					className='about-main-container'
					data-scroll-container
					ref={scrollRef}>
					<section className='hero-container'>
						<div data-scroll data-scroll-speed='-8' className='hero-image'>
							<img
								data-scroll
								className='hero-actual-image'
								src={profile}
								alt='profile'
							/>
						</div>
						<div data-scroll data-scroll-speed='-5' className='hero-title'>
							<p>Andrea Osmar</p>
						</div>
					</section>

					<section
						data-scroll
						data-scroll-speed='0'
						className='skills-container'
						id='up'>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='2'
							data-scroll-target='#up'>
							UX Designer
						</p>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='-6'
							data-scroll-target='#up'>
							UI Designer
						</p>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='4'
							data-scroll-target='#up'>
							Frontend Developer
						</p>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='-6'
							data-scroll-target='#up'>
							Yogini
						</p>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='2'
							data-scroll-target='#up'>
							Backpacker
						</p>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='-2'
							data-scroll-target='#up'>
							Consultant
						</p>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='2'
							data-scroll-target='#up'>
							Swedish
						</p>
						<p
							className='skills-title'
							data-scroll
							data-scroll-direction='horizontal'
							data-scroll-speed='-2'
							data-scroll-target='#up'>
							Chilean
						</p>
					</section>

					<section className='mobile' data-scroll id='bottom'>
						<ul>
							<li>UX Designer</li>
							<li>UI Designer</li>
							<li>Frontend Developer</li>
							<li>Yogini</li>
							<li>Backpacker</li>
							<li>Consultant</li>
							<li>Swedish</li>
							<li>Chilean</li>
						</ul>
					</section>

					<section data-scroll className='intro-container'>
						<p>
							Hi there! I’m Andrea, a colorful, creative UX/UI designer and
							developer. I am also certified 'Professional in Accessibility Core
							Competencies'. I enjoy working with problem solving and being able
							to let out my creativity every day. I put much value into
							designing and developing with accessibility and customer focus in
							mind.
							<br />
							<br />
							Currently I work as a UX designer at the Swedish bank SEB where I
							work with foreign exchange and trading. I am used to working in an
							agile environment and I’m not afraid to take on new challenges and
							learn new frameworks and programs. I enjoy working in a team where
							I can learn from others as well as share my own knowledge and
							experiences.
							<br />
							<br />
							In my spare time, I volunteer with the association DataTjej,
							AddHer, and act as a code coach at Tjejer Kodar to inspire and
							engage more women to seek into the IT industry.
						</p>
					</section>

					<section className='bottom-container'>
						<div data-scroll data-scroll-speed='9' className='bottom-title'>
							<p>
								Get to know me better, have a look at my{' '}
								<a
									className='bottom-link'
									href={CV}
									target='_blank'
									rel='noopener noreferrer'>
									resume
								</a>
							</p>
						</div>
						<div
							data-scroll
							data-scroll-speed='2'
							className='bottom-image-container'>
							<img
								className='bottom-image'
								src={profile1}
								alt='profile of myself'
							/>
						</div>
					</section>
				</div>
			</MenuManager>
		</>
	);
}
