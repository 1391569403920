import React from 'react';
import './style.scss';

export default function Featured() {
	return (
		<section className='featured' data-scroll-section>
			<div className='featured-container'>
				<img
					src='https://tjejerkodar.se/wp-content/uploads/2022/10/Tjejer-Kodar-white.svg'
					alt='bild'
					data-scroll
				/>
			</div>
		</section>
	);
}
