import React from 'react';
import './style.scss';
import dt from '../../../assets/dt2.jpg';

export default function Featured() {
	return (
		<section className='featured' data-scroll-section>
			<div className='featured-container'>
				<img
					src={dt}
					className='featured-img-dt'
					alt='DataTjej conference 2022'
					data-scroll
				/>
			</div>
		</section>
	);
}
