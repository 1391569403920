import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader
				title='My Role: Volunteer Coach'
				subtitle='Length: Ongoing'
			/>
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>Company</h2>
				Tjejer Kodar is a leader in courses and training in programming for
				women in Sweden. Their goal is to lower the thresholds and get more
				women to try programming. They organize code challenges, events, and run
				a school for programming.
				<br />
				<br />
				<h2>My Role</h2>
				I work as a volunteer at Tjejer Kodar during their code challenges. I
				then act as a coach and help the participants with various challenges
				they encountered during the tasks.
				<br />
				<br />
			</div>

			<div className='mobile'>
				<h2>Company</h2>
				Tjejer Kodar is a leader in courses and training in programming for
				women in Sweden. Their goal is to lower the thresholds and get more
				women to try programming. They organize code challenges, events, and run
				a school for programming.
				<br />
				<br />
				<h2>My Role</h2>
				I work as a volunteer at Tjejer Kodar during their code challenges. I
				then act as a coach and help the participants with various challenges
				they encountered during the tasks.
				<br />
				<br />
			</div>
			<section className='image-container'>
				<img
					className='img-tk'
					src='https://teknifik.se/wp-content/uploads/elementor/thumbs/tjejerkodar-man-facebook-pavq207bt885hr6ho0vkk4pgxxa7an0bavk03ixiec.jpg'
					alt='Tjejer Kodar code challenge '
				/>
			</section>
		</section>
	);
}
