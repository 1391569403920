import React from "react";
import "./style.scss";

export default function Featured() {
  return (
    <section className="featured" data-scroll-section>
      <div className="featured-container">
        <img
          className="sg-img"
          src="https://www.kindpng.com/picc/m/97-977069_saint-gobain-weber-logo-png-weber-saint-gobain.png"
          alt="Saint Gobain Logo"
          data-scroll
        />
      </div>
    </section>
  );
}
