import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';
import st2 from '../../../assets/st2.jpg';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section-sogeti', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader
				title='My Role: Designer & Developer consultant'
				subtitle='Length: 1 year, 6 months'
			/>
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>Consultant</h2>
				The Sogeti Group is an information technology consulting company
				specializing in local professional services. <br /> I Started my journey
				at Sogeti by attending their trainee program. Since then I have been
				working as a developer & designer consultant.
				<br />
				<br />
				<h2>Cases</h2>
				Sogeti has customers around the globe and I have been fortunate to be a
				part of a bunch of fun projects. I have mainly worked with development
				of Proof of Concept apps and sites.
				<br />
				Clients worked with: Yaytrade, Saint-Gobain, Mälardalstrafik, Ikea &
				Sandvik.
				<h2>The Extra</h2>
				At Sogeti I volunteer with the organization #AddHer where we work to
				empower women in the tech industry. I Also act as a coach and mentor at
				the Sogeti traineeprogram.
			</div>
			<div className='mobile'>
				<h2>Consultant</h2>
				The Sogeti Group is an information technology consulting company
				specializing in local professional services. I Started my journey at
				Sogeti by attending their trainee program. Since then I have been
				working as a developer & designer consultant.
				<br />
				<br />
				<h2>Cases</h2>
				Sogeti has customers around the globe and I have been fortunate to be a
				part of a bunch of fun projects. I have mainly worked with development
				of Proof of Concept apps and sites.
				<br />
				Clients worked with: Yaytrade, Saint-Gobain, Mälardalstrafik, Ikea &
				Sandvik.
				<h2>The Extra</h2>
				At Sogeti I volunteer with the organization #AddHer where we work to
				empower women in the tech industry. I Also act as a coach and mentor at
				the Sogeti traineeprogram.
			</div>
			<section className='image-container'>
				<img
					className='img-st'
					src={st2}
					alt='Andrea with quote from trainee program'
				/>
			</section>
		</section>
	);
}
