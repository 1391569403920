import React from "react";
import "./style.scss";

export default function Featured() {
  return (
    <section className="featured" data-scroll-section>
      <div className="featured-container">
        <img
          className="sv-img"
          src="https://cdn.mtdcnc.global/cnc/wp-content/uploads/2019/09/01221656/Sandvik_Whiteout.png"
          alt="Sandvik Coromant logo"
          data-scroll
        />
      </div>
    </section>
  );
}
