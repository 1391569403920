import React, { useEffect, useState } from 'react';
import locomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss';
import './skills.scss';
import { X } from 'react-feather';
import MenuManager from '../components/Menu/MenuManager';
import Menu from '../components/Menu';
import { galleryData } from '../gallery-data';

export default function Skills() {
	const scrollRef = React.createRef();
	useEffect(() => {
		const scroll = new locomotiveScroll({
			el: scrollRef.current,
			smooth: true,
			getDirection: true,
			getSpeed: true,
			smartphone: {
				smooth: false,
			},
			tablet: {
				smooth: false,
			},
		});
	});

	const [model, setModel] = useState(false);
	const [tempImgSrc, setTempImgSrc] = useState('');

	const getImg = (imgSrc) => {
		setTempImgSrc(imgSrc);
		setModel(true);
	};

	return (
		<>
			<MenuManager>
				<Menu />
				<div
					className='skills-main-container'
					data-scroll-container
					ref={scrollRef}>
					<section data-scroll-section className='base-container'>
						<h1 className='skills-header'>Certifications</h1>
						<div className={model ? 'model open' : 'model'}>
							<img src={tempImgSrc} alt='certification' />
							<X onClick={() => setModel(false)} />
						</div>
						<div className='gallery' id='gallery'>
							{galleryData.map((item, index) => {
								return (
									<div
										className='pics'
										key={index}
										onClick={() => getImg(item.imgSrcL)}>
										<img
											className='gallery-img'
											src={item.imgSrc}
											alt='certification logo'
										/>
									</div>
								);
							})}
						</div>
					</section>
				</div>
			</MenuManager>
		</>
	);
}
