import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader
				title='My Role: Fullstack developer'
				subtitle='Length: 2 months'
			/>
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>The Company</h2>
				Weber, the worldwide leader in mortar-based solutions with 10,000 people
				in 60 countries supported by almost 200 production units.
				<br />
				<br />
				<h2>My Role</h2>
				Fullstack developer Consultant creating a{' '}
				<abbr title='Proof of Concept'>POC</abbr> webshop with the aim of making
				it easier for companies to place their orders with Saint-Gobain Weber. I
				worked close with the customer and the design team throughout the
				project.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Kanban as method.
				Site was built with Svelte in frontend and Node.js and SQL in backend.
				<br />
				<br />
				We worked with programs such as Figma, VS Code, Trello, Teams, Discord,
				Slack, Github
			</div>

			<div className='mobile'>
				<h2>The Company</h2>
				Weber, the worldwide leader in mortar-based solutions with 10,000 people
				in 60 countries supported by almost 200 production units.
				<br />
				<br />
				<h2>My Role</h2>
				Fullstack developer Consultant creating a{' '}
				<abbr title='Proof of Concept'>POC</abbr> webshop with the aim of making
				it easier for companies to place their orders with Saint-Gobain Weber. I
				worked close with the customer and the design team throughout the
				project.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Kanban as method.
				Site was built with Svelte in frontend and Node.js and SQL in backend.
				<br />
				<br />
				We worked with programs such as Figma, VS Code, Trello, Teams, Discord,
				Slack, Github
			</div>

			<section className='image-container'>
				<img
					className='img-sg'
					src='https://www.saint-gobain.com/sites/saint-gobain.com/files/styles/container_xl/public/media/image/2022-01/211223_header_Weber_1920x620.jpg?itok=eqhrbFZG'
					alt='Saint Gobain warehouse'
				/>
			</section>
		</section>
	);
}
