import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader
				title='My Role: Accessibility designer & developer'
				subtitle='Length: 2 months'
			/>
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>The Client</h2>
				Mälardalstrafik is responsible for Mälartåg's five community-funded
				regional train lines and the period ticket Movingo.
				<br />
				<br />
				<h2>The Project</h2>
				As a frontend developer consultant in the tech team my main focus was to
				adjust the accessibility of the ticket app before launch. I was
				responsible for developing and testing the accessibility flow of the
				app.
				<h2>The Tech</h2>
				Built in Dart and Flutter, testing took place with screen reader
				TalkBack on Android and VoiceOver on iOS.
			</div>
			<div className='mobile'>
				<h2>The Client</h2>
				Mälardalstrafik is responsible for Mälartåg's five community-funded
				regional train lines and the period ticket Movingo.
				<br />
				<br />
				<h2>The Project</h2>
				As a frontend developer consultant in the tech team my main focus was to
				adjust the accessibility of the ticket app before launch. I was
				responsible for developing and testing the accessibility flow of the
				app.
				<h2>The Tech</h2>
				Built in Dart and Flutter, testing took place with screen reader
				TalkBack on Android and VoiceOver on iOS.
			</div>
			<section className='image-container'>
				<img
					className='img-mt'
					src='https://via.tt.se/data/images/00087/c80d8704-2d57-4e5d-92b3-54a7361ec44e.jpg/social'
					alt='Mälardalstrafik logo'
				/>
			</section>
		</section>
	);
}
