import React, { useState, useRef, useEffect } from "react";
import Header from "./Header";
import Featured from "./Featured";
import About from "./About";
import Footer from "./Footer";
import MenuManager from "../../components/Menu/MenuManager";
import Menu from "../../components/Menu";
import "./style.scss";
import useLocoScroll from "../../components/hooks/useLocoScroll";

const EYH = () => {
  const ref = useRef(null);
  const [preloader, setPreload] = useState(true);
  useLocoScroll(!preloader);

  const [timer, setTimer] = useState(2);
  const id = useRef(null);
  const clear = () => {
    window.clearInterval(id.current);
    setPreload(false);
  };

  useEffect(() => {
    id.current = window.setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    // return () => clear();
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clear();
    }
  }, [timer]);

  return (
    <>
      {preloader ? (
        <div className="loader-wrapper absolute">
          <h1>Enjoy Your Home</h1>
          <h2>Ireland</h2>
        </div>
      ) : (
        <MenuManager>
          <Menu />
          <div
            className="project-container"
            id="project-container"
            data-scroll-container
          >
            <Header />
            <Featured />
            <About />
            <Footer />
          </div>
        </MenuManager>
      )}
    </>
  );
};

export default EYH;
