import React from "react";
import "./style.scss";

export default function Footer() {
  return (
    <section data-scroll-section data-scroll-position="bottom">
      <div className="footer-container" data-scroll>
        <a
          data-scroll
          href="https://www.technigo.io/?utm_source=google&utm_medium=cpc&utm_campaign=SE_Web_BC_PMaxFall2022&utm_adgroup=&utm_keyword=&device=c&gclid=CjwKCAjw6fyXBhBgEiwAhhiZss6ViqGwYcaofSgKNLFOWZl1nmcBUQ8lDtbS9WYiJE2b4Lxcm86kpBoC0FQQAvD_BwE"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Technigo
        </a>
      </div>
    </section>
  );
}