import React, { useEffect } from 'react';
import locomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/src/locomotive-scroll.scss';
import './skills.scss';
import MenuManager from '../components/Menu/MenuManager';
import Menu from '../components/Menu';

export default function Work() {
	const scrollRef = React.createRef();
	useEffect(() => {
		const scroll = new locomotiveScroll({
			el: scrollRef.current,
			smooth: true,
			getDirection: true,
			getSpeed: true,
			smartphone: {
				smooth: false,
			},
			tablet: {
				smooth: false,
			},
		});
	});

	return (
		<>
			<MenuManager>
				<Menu />
				<div
					className='about-main-container'
					data-scroll-cosntainer
					ref={scrollRef}>
					<section data-scroll-section className='hero-container'>
						<div data-scroll data-scroll-speed='-8'>
							<p data-scroll data-scroll-speed='-5' className='hero-title'>
								Work in Progress
							</p>
							<img
								data-scroll
								className='hero-actual-image'
								src='https://images.unsplash.com/photo-1523049673857-eb18f1d7b578?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1375&q=80'
								alt='Banana on pink background'
							/>
						</div>
					</section>

					<section data-scroll-section className='base-container'>
						<span className='base-title' data-scroll>
							<span data-scroll data-scroll-delay='0.13' data-scroll-speed='6'>
								e
							</span>
							<span data-scroll data-scroll-delay='0.12' data-scroll-speed='6'>
								a
							</span>
							<span data-scroll data-scroll-delay='0.1' data-scroll-speed='6'>
								t
							</span>
							<span data-scroll data-scroll-delay='0.08' data-scroll-speed='6'>
								&nbsp;
							</span>
							<span data-scroll data-scroll-delay='0.07' data-scroll-speed='6'>
								f
							</span>
							<span data-scroll data-scroll-delay='0.06' data-scroll-speed='6'>
								r
							</span>
							<span data-scroll data-scroll-delay='0.05' data-scroll-speed='6'>
								u
							</span>
							<span data-scroll data-scroll-delay='0.04' data-scroll-speed='6'>
								i
							</span>
							<span data-scroll data-scroll-delay='0.03' data-scroll-speed='6'>
								t
							</span>
						</span>
					</section>
				</div>
			</MenuManager>
		</>
	);
}
