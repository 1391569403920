import React from "react";
import "./style.scss";
import MT from "../../../assets/mt.jpg";

export default function Featured() {
  return (
    <section className="featured" data-scroll-section>
      <div className="featured-container">
        <img className="mt-img" src={MT} alt="Mälardalstrafik app" data-scroll />
      </div>
    </section>
  );
}