import React from 'react';
import './style.scss';

export default function SectionHeader({ title, subtitle }) {
	return (
		<>
			<h6 className='section-header'>{title}</h6>
			<h6 className='section-header'>{subtitle}</h6>
		</>
	);
}
