import React from 'react';
import './style.scss';

export default function Image({ url, opacity, parallaxPos, scale, rotationPosition }) {
  return (
   <img className='projectItem-img' src={url} alt='Project'
    style={{
        opacity: opacity,
        transform: `translate3d(${parallaxPos.x}px, ${parallaxPos.y}px, 0px) rotate(${rotationPosition}deg) scale(${scale})`,
      }}
   />
  )
}
