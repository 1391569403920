/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import locomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/src/locomotive-scroll.scss";
import "./contact.scss";
import { externalLinks } from "../links";
import MenuManager from "../components/Menu/MenuManager";
import Menu from "../components/Menu";

import profile3 from "../assets/profile3.JPG";

export default function Contact() {
  const scrollRef = React.createRef();
  useEffect(() => {
    const scroll = new locomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getDirection: true,
      getSpeed: true,
      smartphone: {
        smooth: false,
      },
      tablet: {
        smooth: false,
      },
    });
  });

  return (
    <>
      <MenuManager>
        <Menu />
        <div
          className="about-main-container"
          data-scroll-container
          ref={scrollRef}
        >
          <section data-scroll-section className="hero-container">
            <div data-scroll data-scroll-speed="-8">
              <p data-scroll data-scroll-speed="-5" className="hero-title">
                Contact & Connect
              </p>
              <img
                data-scroll
                className="hero-actual-image"
                src="https://images.unsplash.com/photo-1594616882521-c0828018a223?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2064&q=80"
                alt="woman at a beach"
              />
              <button data-scroll data-scroll-speed="-10">
                <a
                  href="https://iqwkvh7ldhv.typeform.com/to/ilmg5z29"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact me
                </a>
              </button>
            </div>
          </section>

          <section data-scroll-section className="info-container" id="up">
            <div data-scroll data-scroll-speed="0" className="info-wrapper">
              <h2
                data-scroll
                data-scroll-speed="6"
                data-scroll-target="#up"
                className="info-title"
              >
                Information
              </h2>
              <p data-scroll data-scroll-speed="6" data-scroll-target="#up">
                <span>Name:</span> Andrea Osmar
              </p>
              <p data-scroll data-scroll-speed="5" data-scroll-target="#up">
                <span>Location:</span> Stockholm, Sweden
              </p>
              <p data-scroll data-scroll-speed="4" data-scroll-target="#up">
                <span>Profession:</span> UX/UI Designer & Frontend Developer
              </p>
            </div>
            <div data-scroll data-scroll-speed="2" data-scroll-target="#up">
              <img className="info-image" src={profile3} alt="profile of myself next to colorful wall" />
            </div>
          </section>

          <section data-scroll-section className="base-container">
            <span className="base-title" data-scroll>
              <span data-scroll data-scroll-delay="0.2" data-scroll-speed="6">
                c
              </span>
              <span data-scroll data-scroll-delay="0.16" data-scroll-speed="6">
                o
              </span>
              <span data-scroll data-scroll-delay="0.12" data-scroll-speed="6">
                n
              </span>
              <span data-scroll data-scroll-delay="0.1" data-scroll-speed="6">
                n
              </span>
              <span data-scroll data-scroll-delay="0.08" data-scroll-speed="6">
                e
              </span>
              <span data-scroll data-scroll-delay="0.06" data-scroll-speed="6">
                c
              </span>
              <span data-scroll data-scroll-delay="0.04" data-scroll-speed="6">
                t
              </span>
            </span>
            <ul className="external-nav-links">
              {externalLinks.map((link) => (
                <li key={link.url}>
                  <a href={link.url}>{link.component}</a>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </MenuManager>
    </>
  );
}
