import React from "react";
import "./style.scss";
import vera from "../../../assets/vera.jpg";
import vera1 from "../../../assets/vera1.jpg";

export default function Featured() {
  return (
    <section className="featured-section" data-scroll-section>
      <div className="featured-row-layout">
        <h6>Vera Keohane</h6>
        <img src={vera} alt="Profile of customer" data-scroll />
      </div>

      <div className="featured-column-layout">
        <h6></h6>
        <img src={vera1} alt="Profile of customer Vera Keohane" data-scroll />
      </div>
    </section>
  );
}
