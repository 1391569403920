import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';
import YAY from '../../../assets/yay1.JPG';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader
				title='My Role: Frontend developer & designer'
				subtitle='Length: 5 months'
			/>
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>The Company</h2>
				Yaytrade is a leading fashion/tech e-commerce platform where consumers
				can buy second-hand, overstock, and samples. Through Yaytrade’s unique
				high-tech platform, community members, influencers, and premium brands
				can open digital pop-up shops and use the company’s pick-up service.
				Spherio Solutions makes resale simple through cutting-edge SaaS and 360°
				logistic service.
				<br />
				<br />
				<h2>My Role</h2>
				As a frontend developer consultant in the tech team my main focus was to
				maintain the existing Yaytrade webshop and also improve the site’s SEO.
				I was also fortunate to be a part of building and designing the
				components for the brand new Spherio Solutions site.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Scrum as method. I
				was a part of the tech team working with both Yaytrade and Spherio
				Solutions. Design was created in Figma, the pages built in Next.js,
				Material UI, Sass & Typescript. We worked with programs such as
				Storybook UI Library, Storyblok , Locize, Jira, Confluence, Github,
				Slack.
			</div>
			<div className='mobile'>
				<h2>The Company</h2>
				Yaytrade is a leading fashion/tech e-commerce platform where consumers
				can buy second-hand, overstock, and samples. Through Yaytrade’s unique
				high-tech platform, community members, influencers, and premium brands
				can open digital pop-up shops and use the company’s pick-up service.
				Spherio Solutions makes resale simple through cutting-edge SaaS and 360°
				logistic service.
				<br />
				<br />
				<h2>My Role</h2>
				As a frontend developer consultant in the tech team my main focus was to
				maintain the existing Yaytrade webshop and also improve the site’s SEO.
				I was also fortunate to be a part of building and designing the
				components for the brand new Spherio Solutions site.
				<br />
				<br />
				<h2>The Tech</h2>
				The team worked together in an Agile environment with Scrum as method. I
				was a part of the tech team working with both Yaytrade and Spherio
				Solutions. Design was created in Figma, the pages built in Next.js,
				Material UI, Sass & Typescript. We worked with programs such as
				Storybook UI Library, Storyblok , Locize, Jira, Confluence, Github,
				Slack.
			</div>
			<section className='image-container'>
				<img
					className='img-yay'
					src={YAY}
					alt='Spherio Groups 4 different logos'
				/>
			</section>
		</section>
	);
}
