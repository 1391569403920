import React, { useEffect } from 'react';
import './style.scss';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';

export default function Header() {
	useEffect(() => {
		const split = new SplitText('#title', {
			type: 'lines',
			linesClass: 'lineChildren',
		});

		const splitParent = new SplitText('#title', {
			type: 'lines',
			linesClass: 'lineParent',
		});

		gsap.to(split.lines, {
			duration: 1,
			y: 0,
			opacity: 1,
			stagger: 0.1,
			ease: 'power2',
		});
	}, []);

	return (
		<section className='container' data-scroll-section>
			<h1 id='title' className='title-yay'>
				Yaytrade
			</h1>
		</section>
	);
}
