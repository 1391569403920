import React from "react";
import "./style.scss";

export default function Featured() {
  return (
    <section className="featured" data-scroll-section>
      <div className="featured-container">
        <img
        className="ikea-img"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c5/Ikea_logo.svg/2560px-Ikea_logo.svg.png"
          alt="Ikea Logo"
          data-scroll
        />
      </div>
    </section>
  );
}
