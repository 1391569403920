import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../SectionHeader';
import gsap from 'gsap';
import SplitText from '../../../components/utils/Split3.min.js';
import useOnScreen from '../../../components/hooks/useOnScreen';
import cn from 'classnames';
import './style.scss';
import dt1 from '../../../assets/dt1.jpg';

export default function About() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);

	useEffect(() => {
		if (reveal) {
			const split = new SplitText('#headline', { type: 'lines' });

			gsap.to(split.lines, {
				duration: 1,
				y: -20,
				opacity: 1,
				stagger: 0.1,
				ease: 'power4.out',
				// onComplete: () => split.revert(),
			});
		}
	}, [reveal]);

	return (
		<section
			className={cn('about-section', { 'is-reveal': reveal })}
			data-scroll-section>
			<SectionHeader title='My role: Event Manager' subtitle='Length: 1 year' />
			<div ref={ref} id='headline' className={cn({ 'is-reveal': reveal })}>
				<h2>Company</h2>
				DataTjej is a non-profit association for women and non-binaries who
				study or work in IT and computer science. Every year, DataTjej arranges
				one of Sweden's largest IT conferences as well as a number of different
				activities and lectures with the purpose of creating contacts in the
				industry. The organization switches boards and event groups so that each
				participant retains their position for one year.
				<br />
				<br />
				<h2>My Role</h2>
				My position at DataTjej was as an Event Manager. Here I could tie
				together my experience from working in the event & hotel industry with
				my passion for IT and Tech. It was my responsibility to plan, organize
				and execute the grand final banquet and afterparty of the large
				conference of 2022.
				<br />
				<br />
				<h2>The Project</h2>
				The conference lasted for 3 days and included speakers from major tech
				companies in Sweden, inspirational speakers, a career fair and of course
				the major final banquet event. The banquet, which was my main focus, was
				held in Posthuset. The memorable night contained everything from
				standup-comedy, live music, quizzes and challenges and ended of course
				with a group hug with all the remaining guests.
			</div>
			<div className='mobile'>
				<h2>Company</h2>
				<p>
					DataTjej is a non-profit association for women and non-binaries who
					study or work in IT and computer science. Every year, DataTjej
					arranges one of Sweden's largest IT conferences as well as a number of
					different activities and lectures with the purpose of creating
					contacts in the industry. The organization switches boards and event
					groups so that each participant retains their position for one year.
				</p>

				<h2>My Role</h2>
				<p>
					My position at DataTjej was as an Event Manager. Here I could tie
					together my experience from working in the event & hotel industry with
					my passion for IT and Tech. It was my responsibility to plan, organize
					and execute the grand final banquet and afterparty of the large
					conference of 2022.
				</p>
				<h2>The Project</h2>
				<p>
					The conference lasted for 3 days and included speakers from major tech
					companies in Sweden, inspirational speakers, a career fair and of
					course the major final banquet event. The banquet, which was my main
					focus, was held in Posthuset. The memorable night contained everything
					from standup-comedy, live music, quizzes and challenges and ended of
					course with a group hug with all the remaining guests.
				</p>
			</div>
			<section className='image-container'>
				<img className='img-dt' src={dt1} alt='DataTjej conference 2022' />
			</section>
		</section>
	);
}
